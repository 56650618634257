.cb-c-alert-list-item {
  cursor: pointer;
  margin-bottom: 10px;
 
  &__drop-shadow {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }
 
  &__value {
    font-weight: 500 !important;
  }
 
  &__description,
  &__alert-reference {
    display: block;
 
    @include ln-respond-to("sm") {
      display: inline-block;
    }
  }
 
  &__description {
    @include ln-respond-to("sm") {
      padding-right: $ln-space-x2;
    }
  }
 
  &__sensor-detail {
    padding-bottom: 10px;
  }
}
 
/* Only align text to the right when screen size is larger than mobile. */
.cb-u-text-align-right-responsive {
  text-align: left !important;
 
  @include ln-respond-to("sm") {
    text-align: right !important;
  }
}
 
/* Only align text to the centre when screen size is larger than mobile. */
.cb-u-text-align-center-responsive {
  text-align: left !important;
 
  @include ln-respond-to("sm") {
    text-align: center !important;
  }
}
 
.cb-c-alert-filter a {
  cursor: pointer;
}
 
.cb-c-alert-filter li {
  padding: 0.5rem 0 0.5rem 1rem !important;
}
 
.cb-c-alert-filter__is-active {
  font-weight: bold;
}
 
.cb-u-component-header-title-filters {
  padding-left: 0.5rem;
}
 
 
.cb-c-alerts-page {
  &__left-panel,
  &__right-panel {
    height: 100%;
  }
 
  &--hidden {
    display: none !important;
  }
 
  &__filter-container,
  &__filter-container--full-screen {
    background-color: #f6f6f6;
    position: absolute;
    width: 100%;
    padding-right: 2rem !important;
    z-index: 1;
    transform: translate(-110%);
    transition: 0.5s;
  }
 
  &__filter-container--full-screen {
    transform: translate(0) !important;
  }
 
  &__options {
    float: left;
    width: 95%;
 
    &__close-button {
      float: left;
      width: 5%;
    }
  }
}
 
.cb-c-alerts-page__left-panel .cb-c-stores-dropdown {
  display: block !important;
}
 
.loader {
  position: fixed;
  top: 0;
  // right: 0;
  width: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: rgb(197, 197, 197);
}
 
.loader::after {
  content: ''; /* Add this line to create a pseudo-element */
  width: 75px;
  height: 75px;
  border: 15px solid black;
  border-top-color: #f06c00;
  border-radius: 50%;
  animation: loaderAnimation 0.75s ease infinite; /* Use the correct animation name */
}
 
@keyframes loaderAnimation {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.incidents_details{
  overflow: scroll;
  
  &::-webkit-scrollbar { 
    height: 0.5rem; 
  } 
}

.linked_work_orders_accordian{
  overflow: scroll;

  &::-webkit-scrollbar { 
    height: 0.5rem; 
  } 
}

.workorder_details_table{

  @media (max-width: 800px){
    .change_status_and_submit{
      width: max-content;
    }
  }

  @media (max-width: 586px){
    overflow-y: scroll;
  }
  &::-webkit-scrollbar { 
    height: 0.5rem; 
  } 
}
 
.workorderdetails{
  /* padding: 50px; */
  align-items: center;
  text-align: start;
  justify-content: space-between;
  width: 100%;
  /* background-color: green; */
  border-top: red;
}
.workorderdetails tr{
  border-color: 2px solid black;
  border: 1px solid blue;
}

.contractor_contact_table{
  @media (max-width: 586px){
    overflow-y: scroll;
  }
  &::-webkit-scrollbar { 
    // width: 0.3rem; /* Width of the vertical scrollbar */ 
    height: 0.5rem; /* Height of the horizontal scrollbar */ 
  } 
}

.linkedworkorder{
  width: -webkit-fill-available;
  .tableNotes{
    width: 30%;
  }
  .tableEmail{
    width: 15%;
  }
  .tablePhone{
    width: 10%;
  }
  .tableName{
    width: 12%;
  }
}
.workorderdetails th , .workorderdetails td ,.linkedworkorder th, .linkedworkorder td{
  padding-left: 10px;
  // text-align: right;
  padding-right:10px;
  width: 200px;
  height: 50px;
  border: 1px solid #dddddd;
}
.workorderdetails td , .linkedworkorder td{
  column-gap: 10px;
  text-align: left;
}
.workorderdetails th{
  text-align: right;
  background-color: rgb(165, 165, 152);
  width:200px;
}
.workorderdetailsbutton{
  width: max-content;
}
.linkedworkorder th{
  background-color: rgb(165, 165, 152);
  width:13%;
  text-align: center;
}
.head{
  width: 90px !important;
}
.head1{
  width:180px !important;
}
.linkedworkorder td{
 font-size: small;
 font-weight: 100;
}

.linkbutton:hover{
cursor: pointer;

}

.workorder_updates{
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-gap: 20px;
  border-left: 3px solid rgb(193 182 182);
  width: 50%;
  margin-left: 50px;
  padding-bottom:-5%;
  padding-top:3%;

  @media(max-width: 560px) {
    margin-left: 0px;
    width: 100%;
  }
}

.tiles_card{
  height: 120px;
  background-color: orange;
  color: white;
  width: 100%;
  margin: 0;
  padding: 10px;
}

.row1{
  text-align:start;
  font-size:large;
  margin:0;
  padding:0;
}
.row3{
  text-align:end;
  font-size:medium;
  margin-right:1rem ;
  padding:0;
}

.row2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: -10px;
  padding: 0;
  h1 , h3 {
    margin: 0;
    padding: 0;
    flex: 1;
    text-align: center;
    text-wrap-mode: nowrap;
  }
  h1{
    font-size: revert;
  }
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  // align-items: center;
  margin: -10px;
  padding: 0;
  h1{
    font-size: revert;
  }
  h3{
    width: min-content;
  }
}

