.contractorForm {
  min-height: 98%;
  margin-top: 8px;
}

.formGridAlign {
  text-align: right !important;
}

.formInput {
  margin-bottom: 0.75rem !important;
  margin-top: 0.75rem !important;
  max-width: 32rem;
}

.formInputMaxWidth {
  max-width: 32rem;
}

.formMultipleInput {
  max-width: 32rem;
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.formButtonGroup {
  display: flex;
  flex-direction: row;
}

.formDiscardButton {
  margin-top: 0.75rem !important;
  width: 100%;
  margin-right: 0.25rem;
}

.formSaveButton {
  margin-top: 0.75rem !important;
  width: 100%;
  margin-left: 0.25rem;
}

@media screen and (max-width: 600px) {
  .formGridAlign {
    text-align: left !important;
  }
}

@media screen and (max-width: 720px) {
  .contractorForm {
    margin-top: 1px;
  }

  .formButtonGroup {
    flex-direction: column;
  }

  .formDiscardButton {
    margin-right: 0px;
  }

  .formSaveButton {
    margin-left: 0px;
  }
}