.weekendCall_contacts_table{
    width: -webkit-fill-available;
    @media (max-width: 586px){
        overflow-y: scroll;
      }
    &::-webkit-scrollbar { 
        height: 0.5rem; 
    } 
    .contractorOnCall_Level{
        width: 10%;
    }
}